import { Injectable } from '@angular/core';
import { API } from 'environments/environment';
import { ServiceBase } from '@app/shared/baseClass/services-base.class';
import { ApiHandler } from '@app/shared/services/api-handler.service';

@Injectable({
  providedIn: 'root'
})
export class CommissionService extends ServiceBase {
  redirectUrl: string;
  constructor(apiHandler: ApiHandler) {
    super(apiHandler);
  }

  /**
   * Add Commission
   * @param formdata
   *
   */
  addCommission(formdata) {
    const url = API.COMMISSION_ENDPOINTS.ADD_COMMISSION;
    return this.apiHandler.apiPost(url, formdata);
  }

   /**
   *Get Commission
   * @param id
   *
   */
  getCommissionDetail(id) {
    const url = API.COMMISSION_ENDPOINTS.GET_COMMISSION_DETAILS(id);
    return this.apiHandler.apiGet(url);
  }

  /**
   * Update Commission
   * @param formdata
   *
   */
  updateCommission(formdata, id) {
    const url = API.COMMISSION_ENDPOINTS. UPDATE_COMMISSION(id);
    return this.apiHandler.apiUpdate(url, formdata);
  }

  /**
   * Delete Commission
   * @param formdata
   *
   */
  deleteCommission(id) {
    const url = API.COMMISSION_ENDPOINTS. DELETE_COMMISSION(id);
    return this.apiHandler.apiDelete(url);
  }

}
